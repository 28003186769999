import React from 'react';
import styled, { keyframes } from 'styled-components';
import parseUnderscores from '../lib/parse-underscores';
import Portal from '../components/portal';

const SPACING = 10;
const HALF_SPACING = 5;

const appear = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0,20%,0);
  }

  to {
    opacity:1;
    transform: translate3d(0,0,0);
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: ${props => props.top + 'px'};
  left: ${props => props.left + 'px'};
  /* background: ${p =>
    p.type === 'sic' ? p.theme.sicColor : p.theme.vocabTipColor}; */
  background:#454545;
  border-radius: 6px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px;
  animation: ${appear} 0.1s ease-in-out;
  z-index: ${p => p.theme.tooltipZIndex};
  text-shadow: rgba(0, 0, 0, 0.25) 0 1px 1px;
  cursor: default;
  &:hover {
    z-index: ${p => p.theme.tooltipZIndex + 1};
  }
`;

const Content = styled.div`
  padding: ${HALF_SPACING}px ${SPACING}px;
  width: auto;
  font-size: 13px;
  font-family: ${p => p.theme.sansSerif};
  line-height: 1.5em;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
`;

const Note = styled.span``;

const Arrow = styled.span`
  width: 0;
  height: 0;
  border: 4px solid transparent;
  /* border-top: 8px solid
    ${p => (p.type === 'sic' ? p.theme.sicColor : p.theme.vocabTipColor)}; */
  border-top: 8px solid #454545;
  background: transparent;
  left: calc(50% - 8px);
  bottom: -12px;
  position: absolute;
`;

class PopOver extends React.Component {
  __containerRef = React.createRef();
  __contentRef = React.createRef();

  state = { parent: null, top: 0, left: 0 };

  static defaultProps = {
    as: 'div',
    show: false,
  };

  componentDidMount() {
    if (!this.props.show) {
      return false;
    }
    this.calculatePosition();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      console.log('SHOW PROP CHANGED, RECALCULATING POS');
      this.calculatePosition();
    }
  }

  calculatePosition = () => {
    const ref = this.__containerRef.current;
    const tip = this.__contentRef.current;

    const refRect = ref.getBoundingClientRect();
    const tipRect = tip.getBoundingClientRect();

    const left = refRect.left + refRect.width / 2 - tipRect.width / 2;
    const top =
      refRect.top -
      tipRect.height -
      refRect.height +
      SPACING +
      SPACING +
      SPACING +
      window.pageYOffset;

    this.setState({ left, top });
  };

  render() {
    const {
      children,
      show,
      head,
      note,
      as: Component,
      type,
      ...props
    } = this.props;

    return (
      <Component ref={this.__containerRef} {...props}>
        {show && (
          <Portal>
            <Container
              type={type}
              top={this.state.top}
              left={this.state.left}
              ref={this.__contentRef}
            >
              <Content length={(note && note.length) || 1}>
                {head && <Title>{head}</Title>}
                <Note {...parseUnderscores(note)} />
              </Content>
              <Arrow type={type} />
            </Container>
          </Portal>
        )}
        {children}
      </Component>
    );
  }
}

export default PopOver;
