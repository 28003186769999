import React from 'react';
import styled from 'styled-components';

const Graphic = styled.svg`
  width: 8px;
`;

const Path = styled.path`
  fill: white;
`;

export default () => (
  <Graphic viewBox="0 0 964.23 1090.42">
    <Path d="M926.73,480.26,112.5,10.16C62.5-18.71,0,17.38,0,75.11V1015.3c0,57.74,62.5,93.82,112.5,65l814.23-470.1C976.73,581.29,976.73,509.12,926.73,480.26Z" />
  </Graphic>
);
