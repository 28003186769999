import React from 'react';
import { withRouter } from 'react-router';
import { setDisplayName, wrapDisplayName } from 'recompose';
import { getPrefs, preferenceKeys } from './preferences-context';

import { CursorManager } from '../lib/cursor-manager';
import {
  makePureCursorElement,
  makePurePlayerElement,
} from '../lib/make-pure-elements';

import { isUndefined } from 'lodash';

const { Provider, Consumer } = React.createContext({});

// TODO: simplify once server removes trailing periods for addresses
const isBeginningOfChapter = element => {
  const address = element.address;
  return address === '000' || address === '000.';
};

const isEndOfChapter = element => {
  if (element === null) {
    return true;
  }
  const { address } = element;
  return element.address === '999' || address === '999.';
};

const isHintElement = element => {
  if (element.address === '001.') {
    return false;
  }
  const type = element.entityType ?? element.entity_type;
  return element && type === 'passage' && element.hint;
};

// returns true only if the element represents something that plays audio
const isAudioTrackElement = element => {
  if (element) {
    const type = element.entity_type || element.entityType;
    switch (type) {
      case 'word':
      case 'music_or_silence':
      case 'gap':
        return true;
      default:
        break;
    }
  }
  return false;
};
// returns true only if the element represents something that plays audio
const isUnselectableElement = element => {
  if (element) {
    const type = element.entity_type || element.entityType;
    return type === 'passage';
  }
  return true;
};

const isEditorialElement = element => {
  if (element) {
    return !isAudioTrackElement(element);
  }
  return false;
};

/**
 * ignore navigation stops if within this time of the current audio time
 * (otherwise you can never go back more than one nav stop)
 */
const REWIND_TOLERANCE_MS = 500;

/**
 * we pause a moment before starting to play from a navigation stop
 * so that when rewinding several you dont get jittery sound
 */
const REWIND_NAV_STOP_PAUSE_MS = 500;

/**
 * Provider Class to match elements and the cursor manager.
 * Uses React.Context to pass down the cursor manager state
 * and to provide helper functions.
 */
export class CursorProvider extends React.Component {
  /**
   * Holds the timer ID so we can clear it on unmount
   */
  __timer = null;

  __audioSource = React.createRef();

  // ====================================================================================
  // Lifecycle Methods
  // ====================================================================================

  constructor(props) {
    super(props);
    this.cursorManager = new CursorManager(props.chapter);
    this.state = {
      currentElement: this.cursorManager.getCurrentElement(),
      chapterPlaying: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { chapter } = this.props;
    // what to do when the chapter changes
    if (prevProps.chapter !== chapter) {
      this.cursorManager = new CursorManager(chapter);
      this.stopLoop();
      this.reloadAudio();
      this.setState({
        currentElement: this.cursorManager.getCurrentElement(),
        chapterPlaying: false,
      });
    }
  }

  componentWillUnmount() {
    // cleanup before unmounting otherwise we'll get errors when
    // the timers try to fire on unmounted components.
    this.stopLoop();
  }

  // ====================================================================================
  // Audio Control methods - used privately
  // ====================================================================================

  // getter for `__audioSource.current`
  get audioSource() {
    return this.__audioSource.current;
  }

  reloadAudio() {
    this.audioSource.load();
  }

  playAudio() {
    this.audioSource.play();
  }

  pauseAudio() {
    this.audioSource.pause();
  }

  isAudioPaused() {
    return this.audioSource.paused;
  }

  audioSeekTo(timestamp) {
    const currentTime = timestamp / 1000;
    this.audioSource.currentTime = currentTime;
  }

  getAudioPositionMs() {
    return Math.round(this.__audioSource.current.currentTime * 1000);
  }

  // ====================================================================================
  // Game loop and related methods
  // ====================================================================================

  // Audio track elements (word, gap, music_or_silence) have a audio_start timestamp which indexes into audio file
  // (unlike Notes and Hints which are added by our editors).  We have to check if the head of the audio player is
  // behind (positive lag) or ahead (negative lag) where it should be. But we only care if audio is playing, else
  // we'll reset the audio player head to the audio_start below
  getAdjustedDuration(element) {
    let lag = 0;
    if (isAudioTrackElement(element) && !this.isAudioPaused()) {
      const audioStart = element.audio_start || element.audioStart;
      lag = audioStart - this.getAudioPositionMs();
    }
    return element.duration + lag;
  }

  syncCurrentElementAndThen(callback = () => {}) {
    this.setState(
      { currentElement: this.cursorManager.getCurrentElement() },
      callback
    );
  }

  // responsible for moving the cursor forward, setting the play / pause / head position of the audio
  // player, and for correctly scheduling the internal timer to run this loop for the next element
  loop = () => {
    // if the current element is an audio track element, check if it has expired
    const { currentElement } = this.state;

    if (isAudioTrackElement(currentElement) && !this.isAudioPaused()) {
      const delta = this.getAdjustedDuration(currentElement);

      // const {entity_type, address, text, duration} = currentElement;
      // console.log(`Delta = ${delta} (${duration}): ${entity_type}: ${address} => ${text}`)

      if (delta > 0) {
        // we still have remaining time
        this.scheduleNextLoop(delta);
        return;
      }
    }

    let newElementIsAHint = false;

    // Calculate new element - we have to catch the edge case where the audio player
    // head is already beyond the end of the next Word - which would manifest as a
    // negative adjusted duration for the Word
    const nextElement = this.cursorManager.findNextMatchingElement(element => {
      if (isHintElement(element)) {
        newElementIsAHint = true;
        return true;
      }

      if (isUnselectableElement(element)) {
        return false;
      }

      return this.getAdjustedDuration(element) > 0 || isEndOfChapter(element);
    });

    if (isEndOfChapter(nextElement)) {
      this.syncCurrentElementAndThen(this.pause);
      return;
    }

    if (isEditorialElement(nextElement)) {
      console.log(nextElement);
      // this.pauseAudio();
    }

    const adjustedDuration = this.getAdjustedDuration(nextElement);
    if (adjustedDuration < 0) {
      this.scheduleNextLoop(5);
      return;
    } else {
      if (newElementIsAHint) {
        const prefs = getPrefs();
        const shouldPause = prefs.get(preferenceKeys.PAUSE_ON_HINTS);

        if (shouldPause) {
          console.log('PAUSED AT HINT', nextElement);
          this.pause();
          this.setState({ currentElement: nextElement });
          return;
        } else {
          this.scheduleNextLoop(5);
          return;
        }
      }
    }

    // the actual state is set asyncronously - so we calculate
    this.setState((prevState, props) => {
      if (isAudioTrackElement(nextElement) && this.isAudioPaused()) {
        const audioStart = isUndefined(nextElement.audio_start)
          ? nextElement.audioStart
          : nextElement.audio_start;

        this.audioSeekTo(audioStart);
        this.playAudio();
      }

      if (adjustedDuration < 0) {
        this.loop();
      } else {
        this.scheduleNextLoop(adjustedDuration);
      }

      return { currentElement: nextElement };
    });
  };

  scheduleNextLoop(afterDuration) {
    this.scheduleNext(this.loop, afterDuration);
  }

  scheduleNext(callThis, afterDuration) {
    if (this.__timer) {
      window.clearTimeout(this.__timer);
    }

    this.__timer = window.setTimeout(callThis, afterDuration);
  }

  /**
   *  clears the timers
   */
  stopLoop() {
    this.pauseAudio();
    if (this.__timer) {
      window.clearTimeout(this.__timer);
    }
  }

  // ====================================================================================
  // Transport control methods
  // ====================================================================================

  play = () => {
    const element = this.state.currentElement;

    /// when resuming playback, if we last paused at a hint…
    if (isHintElement(element)) {
      /// …then we don't play were we left, rather, we find the first audio element in the passage.
      const nextElement = this.cursorManager.findNextMatchingElement(
        isAudioTrackElement,
        false
      );

      // … and we resume playback from such element
      this.jumpTo(nextElement.address, true);
      /// the above instruction will recursively call play() again, so we can return here.
      return;
    }

    const audioStart = element.audio_start || element.audioStart;
    const { duration } = element;
    if (!duration) {
      // loop will find the next element with duration
      this.setState({ chapterPlaying: true }, this.loop);
      return;
    }
    // else we should play this element and then schedule the loop
    this.setState({ chapterPlaying: true }, () => {
      if (isAudioTrackElement(element)) {
        // it's an audio element
        this.audioSeekTo(audioStart);
        this.playAudio();
      }
      this.scheduleNextLoop(duration);
    });
  };

  pause = () => {
    this.setState({ chapterPlaying: false }, this.stopLoop);
  };

  jumpTo = address => {
    this.cursorManager.goTo(address);
    this.pause();
    this.syncCurrentElementAndThen(this.play);
  };

  restart = () => {
    this.cursorManager.resetPosition();
    this.pause();
    this.syncCurrentElementAndThen(this.play);
  };

  rewind = () => {
    const didRewind = this.cursorManager.findPreviousMatchingElement(
      element => {
        const audioStart = element.audio_start || element.audioStart;
        const navigationStop =
          element.navigation_stop || element.navigationStop;

        if (isBeginningOfChapter(element)) {
          return true;
        }
        if (navigationStop) {
          return this.getAudioPositionMs() - audioStart > REWIND_TOLERANCE_MS;
        }
        return false;
      }
    );
    // we pause a moment before playing from navigation stop
    if (didRewind) {
      this.afterMovingTheCursor();
    }
  };

  unwind = () => {
    const nextNavStop = this.cursorManager.findNextMatchingElement(
      element => {
        const navigationStop =
          element.navigation_stop || element.navigationStop;

        if (isEndOfChapter(element)) {
          return true;
        }
        if (navigationStop) {
          return true;
        }
        return false;
      },
      false // don't seek, just return element
    );
    // we don't forward beyond the last listened point
    // we pause a moment before playing from navigation stop
    if (nextNavStop) {
      let { address } = nextNavStop;
      if (this.isVisited(address)) {
        this.cursorManager.goTo(address);
        this.afterMovingTheCursor();
      }
    }
  };

  jumpToLatest = () => {
    const furthestElement = this.cursorManager.getFurthestElement();
    this.jumpTo(furthestElement.address);
  };

  fastRewind = () => {
    const didFF = this.cursorManager.findPreviousMatchingElement(element => {
      if (isBeginningOfChapter(element)) {
        return true;
      }
      const entityType = element.entity_type || element.entityType;
      const audioStart = element.audio_start || element.audioStart;
      if (entityType === 'paragraph') {
        return this.getAudioPositionMs() - audioStart > REWIND_TOLERANCE_MS;
      }
      return false;
    });
    // we pause a moment before playing from navigation stop
    if (didFF) {
      this.afterMovingTheCursor();
    }
  };

  fastForward = () => {
    const didFF = this.cursorManager.findNextMatchingElement(element => {
      if (isEndOfChapter(element)) {
        return true;
      }
      const entityType = element.entity_type || element.entityType;
      const audioStart = element.audio_start || element.audioStart;
      if (entityType === 'paragraph') {
        return this.getAudioPositionMs() - audioStart < REWIND_TOLERANCE_MS;
      }
      return false;
    });
    // we pause a moment before playing from navigation stop
    if (didFF) {
      this.afterMovingTheCursor();
    }
  };

  afterMovingTheCursor() {
    this.pauseAudio();
    this.syncCurrentElementAndThen(() => {
      if (this.state.chapterPlaying) {
        this.scheduleNext(this.play, REWIND_NAV_STOP_PAUSE_MS);
      }
    });
  }

  isChapterEnded = () => {
    return isEndOfChapter(this.state.currentElement);
  };

  // ====================================================================================
  // Cursor comparison methods
  // ====================================================================================

  isCurrentElement = address => {
    return this.state.currentElement.address === address;
  };

  isParentOfCurrentElement = address => {
    return this.state.currentElement.address.startsWith(address);
  };

  isUnderCursor = address => {
    return (
      this.isCurrentElement(address) || this.isParentOfCurrentElement(address)
    );
  };

  isVisited = address => {
    return (
      this.cursorManager.addressMap.get(address) <=
      this.cursorManager.furthestPosition
    );
  };

  isBeforeCursor = address => {
    return (
      this.cursorManager.addressMap.get(address) <=
      this.cursorManager.currentPosition
    );
  };

  // ====================================================================================
  // Render functions
  // ====================================================================================

  renderPlayerElement() {
    const { chapter } = this.props;
    const url = chapter.normal_audio_url || chapter.normalAudioUrl;
    if (url) {
      return (
        <audio ref={this.__audioSource}>
          <source src={url} type="audio/mp3" />
          <p>Your browser doesn&apos;t support HTML5 audio</p>
        </audio>
      );
    } else {
      throw new Error('No audio url found');
    }
  }

  render() {
    // this is super useful in development
    // and webpack gets rid of it in production.
    // this way we can access this class instance from the
    // browser console.
    if (process.env.NODE_ENV !== 'production') {
      window.CursorProvider = this;
    }

    const { chapterPlaying } = this.state;
    return (
      <Provider
        value={{
          current: this.state.currentElement,
          isUnderCursor: this.isUnderCursor,
          isVisited: this.isVisited,
          isBeforeCursor: this.isBeforeCursor,
          play: this.play,
          pause: this.pause,
          jumpTo: this.jumpTo,
          restart: this.restart,
          rewind: this.rewind,
          unwind: this.unwind,
          jumpToLatest: this.jumpToLatest,
          fastRewind: this.fastRewind,
          fastForward: this.fastForward,
          chapterEnded: this.isChapterEnded,
          chapterPlaying,
        }}
      >
        {this.renderPlayerElement()}
        {this.props.children}
      </Provider>
    );
  }
}

/**
 * Higher Order Component (HOC) that make it easier and safer for components
 * to consume the state of the cursor
 */
export const withCursor = (options = {}) => BaseComponent => {
  console.log(options);
  const { makePure = true } = options;
  if (makePure) {
    BaseComponent = withRouter(makePureCursorElement(BaseComponent));
  }
  const Wrapped = props => (
    <Consumer>
      {cursor => (
        <BaseComponent
          {...props}
          isChapterPlaying={cursor.chapterPlaying}
          isUnderCursor={cursor.isUnderCursor(props.root.address)}
          isVisited={cursor.isVisited(props.root.address)}
          isBeforeCursor={cursor.isBeforeCursor(props.root.address)}
        />
      )}
    </Consumer>
  );

  return setDisplayName(wrapDisplayName(BaseComponent, 'withCursor'))(Wrapped);
};

/**
 * Higher Order Component (HOC) that make it easier and safer for components
 * to consume manipulate the state of the cursor.
 */
export const withTransportControls = (BaseComponent, makePure = true) => {
  if (makePure) {
    BaseComponent = makePurePlayerElement(BaseComponent);
  }
  const Wrapped = props => (
    <Consumer>
      {cursor => (
        <BaseComponent
          {...props}
          isChapterPlaying={cursor.chapterPlaying}
          playChapter={cursor.play}
          pauseChapter={cursor.pause}
          restart={cursor.restart}
          jumpTo={cursor.jumpTo}
          rewind={cursor.rewind}
          unwind={cursor.unwind}
          jumpToLatest={cursor.jumpToLatest}
          fastRewind={cursor.fastRewind}
          fastForward={cursor.fastForward}
        />
      )}
    </Consumer>
  );

  return setDisplayName(
    wrapDisplayName(BaseComponent, 'withTransportControls')
  )(Wrapped);
};
