import React from 'react';
import styled from 'styled-components';

const Label = styled.span`
  display: inline-block;
  margin-bottom: 1em;
  font-size: 14px;
  font-family: ${p => p.theme.sansSerif};
  font-weight: 600;
  background: #fff;
  border: 1px solid #e9e9e9;
  padding: 3px 12px;
  border-radius: 4px;
  color: #151515;
`;

const SpeakerLabel = ({ label }) => {
  return (
    <div>
      <Label>{label}</Label>
    </div>
  );
};

export default SpeakerLabel;
