import React from 'react';
import { withCursor } from '../../contexts/cursor-context';
import styled from 'styled-components';
import ElementTree from './element-tree';
import SpeakerLabel from './speaker-label';

const StyledParagraph = styled.div`
  margin-bottom: 1em;
  padding: 1em;
  background: ${p => (p.current ? '#f3f3da ' : 'transparent')};
`;

const Paragraph = ({ isUnderCursor, root: paragraph }) => {
  const { elements, speaker: speakerLabel } = paragraph;

  const speakerLabelRequired =
    paragraph.is_speaker_label_required || paragraph.isSpeakerLabelRequired;
  const showSpeakerNote = speakerLabel && speakerLabelRequired;
  return (
    <StyledParagraph current={isUnderCursor}>
      {showSpeakerNote && <SpeakerLabel label={speakerLabel} />}
      <ElementTree elements={elements} />
    </StyledParagraph>
  );
};

export default withCursor()(Paragraph);
