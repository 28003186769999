import React from 'react';
import { wrapDisplayName, setDisplayName } from 'recompose';
import { get } from 'lodash';

const { Provider, Consumer } = React.createContext({});

export const [REVEAL_ALL, REVEAL_VISITED, REVEAL_NONE] = [0, 1, 2];

export const preferenceKeys = {
  REVEAL_WORDS_LEVEL: 'REVEAL_WORDS_LEVEL',
  AUTO_SHOW_TOOLTIPS: 'AUTO_SHOW_TOOLTIPS',
  SHOW_INLINE_TRANSLATION: 'SHOW_INLINE_TRANSLATION',
  PAUSE_ON_HINTS: 'PAUSE_ON_HINTS',
};

/**
 * Gets serialized JSON from `localStorage[key]`, unserializes it and returns it.
 * @param {string} key
 */
const getPersistedPrefs = (key = 'jwprefs') => {
  const json = window.localStorage.getItem(key) || '{}';
  try {
    return JSON.parse(json);
  } catch (error) {
    return {};
  }
};

/**
 * Serializes `obj` as a JSON string and stores it in `localStorage[key]`
 * @param {Object} obj
 * @param {string} key
 */
const persistPrefs = (obj = {}, key = 'jwprefs') => {
  try {
    const json = JSON.stringify(obj);
    window.localStorage.setItem(key, json);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const defaultPrefs = {
  [preferenceKeys.REVEAL_WORDS_LEVEL]: REVEAL_ALL,
  [preferenceKeys.AUTO_SHOW_TOOLTIPS]: true,
  [preferenceKeys.SHOW_INLINE_TRANSLATION]: true,
  [preferenceKeys.PAUSE_ON_HINTS]: true,
};

let prefsCopy = { ...defaultPrefs };

export function getPrefs() {
  return {
    get(key) {
      return get(prefsCopy, key);
    },
    prefsCopy,
  };
}

/**
 * Provider Class to store local preferences.
 * Uses React.Context to pass down the state
 * and to provide helper functions.
 *
 * The internal state is saved in localStorage.
 *
 */
export class PreferencesProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultPrefs,
      ...getPersistedPrefs(),
    };
  }

  validKey = key => {
    return typeof preferenceKeys[key] !== 'undefined';
  };

  setItem = (key, value, callback = () => {}) => {
    if (!this.validKey(key)) {
      throw new Error(`Invalid key used: ${key}`);
    }
    this.setState({ [key]: value }, () => {
      persistPrefs(this.state);
      // this is an ugly hack, not gonna lie. But it allows us to access the current prefs outside the context
      prefsCopy = { ...this.state };
      console.log('COPY', prefsCopy);

      callback();
    });
  };

  getItem = key => {
    if (!this.validKey(key)) {
      throw new Error(`Invalid key used: ${key}`);
    }

    return this.state[key];
  };

  toggleValue = (key, callback = () => {}) => {
    // if (!preferenceKeys[key]) {
    //   throw new Error(`Invalid key used: ${key}`);
    // }

    this.setItem(key, !this.state[key], callback);

    // this.setState({ [key]: !this.state[key] }, () => {
    //   persistPrefs(this.state);
    //   callback();
    // });
  };

  render() {
    if (process.env.NODE_ENV !== 'production') {
      window.PrefsProvider = this;
    }

    return (
      <Provider
        value={{
          values: this.state,
          setItem: this.setItem,
          getItem: this.getItem,
          toggleValue: this.toggleValue,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

/**
 * Higher Order Component (HOC) that make it possible for components
 * to consume preferences
 */
export const withPreferences = BaseComponent => {
  const Wrapped = props => (
    <Consumer>
      {context => {
        return (
          <BaseComponent
            {...props}
            setPreference={context.setItem}
            getPreference={context.getItem}
            togglePreference={context.toggleValue}
            preferences={context.values}
          />
        );
      }}
    </Consumer>
  );

  return setDisplayName(wrapDisplayName(BaseComponent, 'withPreferences'))(
    Wrapped
  );
};
