import { camelCase } from 'lodash';

// this will only work for simple queries, but it's enough for our needs
export function parseQuery() {
  const query = window.location.search.substr(1);
  const obj = {};

  if (query) {
    const pairs = query.split('&');
    pairs.forEach(pair => {
      const [key, value] = pair.split('=');
      obj[camelCase(key)] = value || null;
    });
  }

  return obj;
}
