import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { formatDuration } from '../lib/format-duration';
import styled from 'styled-components';

// import romanNumeral from '../lib/numeral';

const camelCaseToPhrase = s =>
  s
    .replace(/[A-Z]/g, a => ` ${a.toLowerCase()}`)
    .replace(/^./, a => a.toUpperCase());

const Container = styled.div`
  font-family: ${p => p.theme.sansSerif};
  border-right: 1px solid #eee;
  min-height: 100vh;
  padding-bottom: 200px;
  background: #e4f3f5;
  background: #f1f6f6;
  background: #e7f0f2;
  /* background: #e9f3f3; */
`;

const ChapterLink = styled(Link)`
  color: ${p => (p.active ? '#fff' : '#555')};
  background: ${p => (p.active ? p.theme.jumpColor : 'transparent')};
  padding: 6px 18px;
  font-size: 12px;
  line-height: 1.5em;
  text-decoration: none;
  display: flex;
  &:hover {
    background: ${p => (p.active ? p.theme.jumpColor : '#bae6ee')};
  }

  & > .numeral {
    font-size: 11px;
    font-weight: bold;
    margin-right: 0.5em;
    width: 11px;
  }

  & > .title {
    .duration {
      margin-left: 1ch;
    }
  }
`;

const Text = styled.span`
  font-family: ${p => p.theme.sansSerif};
  font-size: ${p => (p.fs ? p.fs * 12 + 'px' : 'default')};
  color: ${p => (p.strong ? '#222' : '#555')};
  font-weight: ${p => (p.strong ? 'bold' : 'regular')};
`;

const AccentText = styled(Text)`
  margin: 3px 0;
  color: white;
  background: rgba(127, 127, 127, 0.5);
  padding: 3px 6px;
  border-radius: 6px;
  display: inline-block;
  text-shadow: rgba(0, 0, 0, 0.15) 1px 1px 0;
`;

const Box = styled.div`
  padding: 0.5em 0;
`;

const Dict = ({ label, value }) => (
  <div>
    <Text as="span" strong fs={1}>
      {label}:
    </Text>
    &nbsp;
    <Text as="span" fs={1}>
      {value}
    </Text>
  </div>
);

const Credits = ({ credits }) => {
  const keys = Object.keys(credits);
  // const keys = Object.keys(credits).map(snakeCaseToPhrase);
  return keys.map(key => (
    <Dict key={key} label={camelCaseToPhrase(key)} value={credits[key]} />
  ));
};

const Cast = ({ cast = [] }) =>
  cast.map(person => (
    <Box key={person.fullName}>
      <Text as={'div'} fs={1.2}>
        <Text as={'strong'}>{person.fullName}</Text>
        {person.shortName && ` (${person.shortName})`}
      </Text>
      <Text as={'div'} fs={1}>
        {person.description}
      </Text>
      {person.accent && (
        <AccentText as={'div'} fs={1}>
          {person.accent}
        </AccentText>
      )}
    </Box>
  ));

const InfoContainer = styled.div`
  padding: 12px;
  color: #444;
`;

const EpisodeBanner = styled.img`
  max-width: 100%;
`;

const EpisodeTitle = styled.h1`
  font-size: 24px;
  color: #444;
  margin: 0;
  padding: 0 12px;
`;

const EpisodeTagline = styled.div`
  font-size: 12px;
  padding: 12px;
  color: #444;
`;

const Info = ({ episode }) => (
  <>
    <InfoContainer>
      <Box>
        <Dict
          label={'Time'}
          value={
            (episode.duration_minutes || episode.durationMinutes) + ' minutes'
          }
        />
      </Box>
      <Box>
        <Text as={'p'} fs={1}>
          {episode.description}
        </Text>
      </Box>
      <Box>
        <Dict label={'Themes'} value={episode.topics.join('; ')} />
        <Dict label={'Countries'} value={episode.countries.join('; ')} />
      </Box>
      <Box>
        <Credits credits={episode.credits} />
      </Box>
      <Text as="h2" fs={1.5}>
        Voices
      </Text>
      <Box>
        <Cast cast={episode.cast} />
      </Box>
    </InfoContainer>
  </>
);

let ChapterNav = ({ episode, match }) => {
  const {
    params: { chapterNumber = 1, episodeSlug = 'adhoc' },
  } = match;
  const { search } = window.location;
  const chapterIndex = chapterNumber - 1;
  return (
    <Box>
      {episode.chapters.map((chapter, index) => (
        <ChapterLink
          key={index}
          to={`/${episodeSlug}/${index + 1}${search}`}
          active={chapterIndex === index}
        >
          <span className="numeral">{index + 1}</span>
          <span className="title">
            {chapter.title}
            <span className="duration">
              (
              {formatDuration(
                chapter.durationMillis ?? chapter.duration_millis
              )}
              )
            </span>
          </span>
        </ChapterLink>
      ))}
    </Box>
  );
};

ChapterNav = withRouter(ChapterNav);

const Sidebar = ({ episode }) => (
  <Container>
    <EpisodeBanner
      src={episode.banner_image_url || episode.bannerImageUrl}
      alt={episode.title}
    />
    <EpisodeTitle>{episode.title}</EpisodeTitle>
    <EpisodeTagline>{episode.tagline || 'No tagline set yet'}</EpisodeTagline>
    <ChapterNav episode={episode} />
    <Info episode={episode} />
  </Container>
);

export default Sidebar;
