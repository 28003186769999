import React from 'react';

/**
 * Button that loses focus as soon as it gets clicked.
 * This way the global keyboard events are not swallowed by the control.
 */
export default class UnfocusButton extends React.Component {
  __ref = React.createRef();
  handleFocus = () => {
    this.__ref.current.blur();
  };
  render() {
    return (
      <button {...this.props} ref={this.__ref} onFocus={this.handleFocus} />
    );
  }
}
