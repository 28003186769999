import minibus from '../lib/minibus';

export const WORDGROUP_SHOW_VISITED_TOOLTIPS =
  'WORDGROUP_SHOW_VISITED_TOOLTIPS';
export const WORDGROUP_RESET_TOOLTIPS = 'WORDGROUP_RESET_TOOLTIPS';

export const emitWordGroupShowVisitedTooltips = () => {
  minibus.emit(WORDGROUP_SHOW_VISITED_TOOLTIPS);
};

export const emitWordGroupResetTooltips = () => {
  minibus.emit(WORDGROUP_RESET_TOOLTIPS);
};
