import React from 'react';
import ChapterPosition from './chapter-position';
import ChapterTitle from './chapter-title';
import ChapterNote from './chapter-note';
import ChapterRecap from './chapter-recap';
import ChapterPaging from './chapter-paging';
import ElementTree from './element-tree';

import styled from 'styled-components';

const ChapterContainer = styled.div`
  border-left: 1px dotted #f6f6f6;
  margin-left: 90px;
  padding-bottom: 200px;
  padding-right: 1em;
`;

const Chapter = ({ isUnderCursor, root: chapter }) => {
  const { elements, position, title, notes } = chapter;
  return (
    <ChapterContainer>
      <ChapterPosition position={position} />
      <ChapterTitle
        title={title}
        translation={chapter.title_english || chapter.titleEnglish}
      />
      {notes.map((note, index) => (
        <ChapterNote note={note} isUnderCursor={isUnderCursor} key={index} />
      ))}
      <ElementTree elements={elements} />
      <ChapterRecap chapter={chapter} />
      <ChapterPaging />
    </ChapterContainer>
  );
};

export default Chapter;
