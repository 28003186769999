import React from 'react';
import Play from '../components/play.js';
import styled from 'styled-components';
import { shouldUpdate } from 'recompose';

import { withTransportControls } from '../contexts/cursor-context';

export const makePure = shouldUpdate(
  (cur, next) =>
    cur.element !== next.element || cur.isCurrent !== next.isCurrent
);

const Button = styled.a`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: flex-end;
  padding: 3px 6px;
  border-radius: 12px;
  opacity: 0.8;
  transition-property: all;
  transition-duration: 0.3s;
  color: ${p => (p.current ? 'white' : '#151515')};
  background: ${p => (p.current ? p.theme.jumpColor : '#ccc')};
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 1;
  }
`;

const Label = styled.span`
  font-family: ${p => p.theme.sansSerif};
  color: inherit;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-align: right;
  margin-right: 6px;
  width: 4em;
`;

const JumpTo = ({ jumpTo, element, isCurrent }) => (
  <Button
    current={isCurrent}
    href="#"
    onClick={e => {
      e.preventDefault();
      jumpTo(element.address);
    }}
  >
    <Label>{element.debug_label || element.debugLabel}</Label>
    <Play />
  </Button>
);

export default withTransportControls(makePure(JumpTo), false);
