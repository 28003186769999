import React from 'react';

import { Link, withRouter } from 'react-router-dom';
import { withEpisode } from '../../contexts/episode-context';
import { compose } from 'recompose';
import styled from 'styled-components';

const enhance = compose(
  withRouter,
  withEpisode
);

const Nav = styled.div`
  display: flex;
  padding: 12px 0;
  border-top: 1px solid #f0f0f0;
`;

const ChapterLink = styled(Link)`
  font-family: ${p => p.theme.sansSerif};
  flex: 1;
  padding: 12px;
  color: ${p => p.theme.jumpColor};
  border-radius: 18px;
  font-size: 14px;
  text-decoration: none;
  text-align: ${p => p.textAlign};
  &:hover {
    color: white;
    background-color: ${p => p.theme.jumpColor};
  }
`;

const ChapterPaging = ({ episode, match }) => {
  const { chapters } = episode;
  const {
    params: { episodeSlug = null, chapterNumber = 1 },
  } = match;

  const chapterIndex = parseInt(chapterNumber, 10) - 1;

  const nextIndex = chapterIndex + 1;
  const prevIndex = chapterIndex - 1;
  const nextChapter = chapters[nextIndex];
  const prevChapter = chapters[prevIndex];
  return (
    <Nav>
      {prevChapter && (
        <ChapterLink to={`/${episodeSlug}/${prevIndex + 1}`} textAlign={'left'}>
          &larr;&nbsp;
          {prevChapter.title}
        </ChapterLink>
      )}
      {nextChapter && (
        <ChapterLink
          to={`/${episodeSlug}/${nextIndex + 1}`}
          textAlign={'right'}
        >
          {nextChapter.title}
          &nbsp;&rarr;
        </ChapterLink>
      )}
    </Nav>
  );
};

export default enhance(ChapterPaging);
