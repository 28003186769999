import React from 'react';

import Chapter from './chapter';
import ChapterNote from './chapter-note';
import MusicOrSilence from './music-or-silence';
import Paragraph from './paragraph';
import Passage from './passage';
import Sentence from './sentence';
import WordGroup from './word-group';
import Word from './word';

const NoOp = () => null;

const typeMap = {
  chapter: Chapter,
  passage: Passage,
  paragraph: Paragraph,
  sentence: Sentence,
  word_group: WordGroup,
  chapter_note: ChapterNote,
  music_or_silence: MusicOrSilence,
  word: Word,
  gap: NoOp,
  beginning_of_chapter: NoOp,
  end_of_chapter: NoOp,
};

/**
 * Generic component that takes an element object and decides which
 * component to render based on it's EntityType.
 */
class Element extends React.Component {
  render() {
    const { root, ...props } = this.props;
    const type = root.entity_type || root.entityType;
    const Component = typeMap[type];
    if (Component) {
      return <Component root={root} {...props} />;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(
        `There's no component for type "${type}" at ${root.address}`
      );
    }

    return null;
  }
}

export default Element;
