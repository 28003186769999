import { shouldUpdate } from 'recompose';

export const didChapterChange = (curProps, nextProps) => {
  if (typeof curProps.match == 'undefined') {
    throw new Error(
      'cannot use didChapterChange on a component without enabling withRouter HOC first'
    );
  }
  return (
    curProps.match.params.chapterNumber !== nextProps.match.params.chapterNumber
  );
};

export const didCursorStateChange = (curProps, nextProps) => {
  return (
    curProps.isUnderCursor !== nextProps.isUnderCursor ||
    curProps.isVisited !== nextProps.isVisited ||
    curProps.isBeforeCursor !== nextProps.isBeforeCursor
  );
};

/**
 * This functions prepare components to avoid unnecesary updates.
 */
export const makePureCursorElement = shouldUpdate((curProps, nextProps) => {
  let should =
    didChapterChange(curProps, nextProps) ||
    didCursorStateChange(curProps, nextProps);
  return should;
});

export const makePurePlayerElement = shouldUpdate(
  (curProps, nextProps) =>
    curProps.isChapterPlaying !== nextProps.isChapterPlaying
);
