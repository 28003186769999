import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from '../lib/theme';
import Study from './study';
import Sidebar from '../components/sidebar';
import Transport from '../components/transport';
import { PreferencesProvider } from '../contexts/preferences-context';
import { CursorProvider } from '../contexts/cursor-context';
import { withRouter } from 'react-router';
import { withEpisode } from '../contexts/episode-context';
import { compose } from 'recompose';

const enhance = compose(withRouter, withEpisode);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto');
  @import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
  body {
    margin:0;
    padding:0;
  }
`;

const PanesContainer = styled.div`
  padding-left: 250px;
`;

const SideBarPane = styled.div`
  left: 0;
  top: 0;
  width: 250px;
  height: 100vh;
  position: fixed;
  overflow: auto;
`;
const MainPane = styled.div``;

const TransportContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${p => p.theme.transportZIndex};
`;

const Layout = ({ episode, match }) => {
  const {
    params: { chapterNumber = 1 },
  } = match;
  // to compensate for 0-based indexes and get nicer urls
  const chapterIndex = chapterNumber - 1;
  const chapter = episode.chapters[chapterIndex];

  return (
    <ThemeProvider theme={theme}>
      <PreferencesProvider>
        <GlobalStyle />
        <CursorProvider chapter={chapter}>
          <PanesContainer>
            <SideBarPane>
              <Sidebar episode={episode} />
            </SideBarPane>
            <MainPane>
              <Study chapter={chapter} episode={episode} />
            </MainPane>
            <TransportContainer>
              <Transport />
            </TransportContainer>
          </PanesContainer>
        </CursorProvider>
      </PreferencesProvider>
    </ThemeProvider>
  );
};

export default enhance(Layout);
