import React from 'react';
import parseUnderscores from '../../lib/parse-underscores';
import { formatDuration } from '../../lib/format-duration';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 2em;
  padding-bottom: 1em;
  display: flex;
  font-family: ${p => p.theme.serif};
  font-size: 18px;
  line-height: 2em;
  color: ${p => (p.current ? 'tomato' : 'gray')};
  .hint-inner {
    margin-left: 1em;
  }
  .hint-duration {
    margin-left: 0.5em;
    opacity: 0.75;
  }
`;

const PassageHint = ({ passage, current }) => {
  return (
    <Wrapper current={current}>
      <span className={'hint-inner'} {...parseUnderscores(passage.hint)} />
      <span className={'hint-duration'}>
        ({formatDuration(passage.duration)})
      </span>
    </Wrapper>
  );
};

export default PassageHint;
