import React from 'react';
import Root from './containers/root';
import Adhoc from './containers/adhoc';
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from 'react-router-dom';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop = withRouter(ScrollToTop);

export default () => (
  <Router basename={BASE_PATH}>
    <ScrollToTop>
      <Switch>
        <Route path="/adhoc/:chapterNumber?" component={Adhoc} />
        <Route path="/:episodeSlug/:chapterNumber?" component={Root} />
      </Switch>
    </ScrollToTop>
  </Router>
);
