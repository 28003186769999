const MATCH_ANYTHING = x => true;

const errorLog = message => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(message);
  }
};

/**
 * Recursively creates two data structures
 * @param {*} rootElement - of tree or subtree
 * @param {*} elementList - list of elements ordered by location in chapter
 * @param {*} elementMap  - lookup by element.address to index into elementList
 */
const constructLookupDataStructures = (
  rootElement,
  elementList,
  addressMap
) => {
  const { elements, address } = rootElement;
  if (!address) {
    errorLog(`Missing address for element = ${rootElement}`);
  } else {
    const newIndexIntoList = elementList.length; // because we'll push it
    addressMap.set(address, newIndexIntoList);
    elementList.push(rootElement);
  }
  if (elements && elements.length) {
    elements.forEach(element => {
      constructLookupDataStructures(element, elementList, addressMap);
    });
  }
};

export class CursorManager {
  constructor(root) {
    this.root = root;

    this.addressMap = new Map();
    this.elementList = [];
    constructLookupDataStructures(root, this.elementList, this.addressMap);

    this.currentPosition = 0;
    this.furthestPosition = 0;

    if (process.env.NODE_ENV !== 'production') {
      window.CursorManager = this;
    }
  }

  getCurrentElement() {
    return this.elementList[this.currentPosition];
  }

  getFurthestElement() {
    return this.elementList[this.furthestPosition];
  }

  setCursorPosition(index) {
    this.currentPosition = index;
    if (index > this.furthestPosition) {
      this.furthestPosition = index;
    }
  }

  resetPosition() {
    this.setCursorPosition(0);
  }

  /**
   * Moves currentPosition to the element that corresponds to `address`
   */
  goTo(address) {
    this.setCursorPosition(this.addressMap.get(address));
  }

  /**
   * Advances the `currentPosition` property by 1,
   * sets the current element to the one found in that position
   * and returns the current element.
   *
   * If we are already in the last position of the addressBook
   * the function will return false.
   */
  next() {
    this.findNextMatchingElement();
  }

  /**
   * Seeks incrementally from just after a starting index (current location by default)
   * and returns the next element matching the passed filter
   */
  findNextMatchingElement(
    filter = MATCH_ANYTHING,
    andSeek = true,
    fromAfterThisIndex = this.currentPosition
  ) {
    for (let i = this.currentPosition + 1; i < this.elementList.length; i++) {
      let element = this.elementList[i];
      if (filter(element)) {
        if (andSeek) {
          this.setCursorPosition(i);
        }
        return element;
      }
    }
    return null;
  }

  /**
   * Seeks incrementally backwards from just before a starting index (current location by default)
   * and returns the next element matching the passed filter
   */
  findPreviousMatchingElement(
    filter = MATCH_ANYTHING,
    andSeek = true,
    fromBeforeThisIndex = this.currentPosition
  ) {
    for (let i = this.currentPosition - 1; i >= 0; i--) {
      let element = this.elementList[i];
      if (filter(element)) {
        if (andSeek) {
          this.setCursorPosition(i);
        }
        return element;
      }
    }
    return null;
  }
}
