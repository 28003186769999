import React from 'react';
import styled from 'styled-components';
import parseUnderscores from '../../lib/parse-underscores';
import ElementTree from './element-tree';
import { compose } from 'recompose';
import { withCursor } from '../../contexts/cursor-context';
import {
  withPreferences,
  preferenceKeys,
} from '../../contexts/preferences-context';
import JumpTo from '../jump-to';
import Linchpin from '../linchpin';

const enhance = compose(
  withCursor(),
  withPreferences
);

const Wrapper = styled.div`
  position: relative;
`;
const JumpContainer = styled.div`
  position: absolute;
  left: -100px;
`;

const StyledSentence = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${p => (p.current ? '#333' : '#777')};
  padding: 8px 0;
`;

const Translation = styled.div`
  font-family: ${p => p.theme.serif};
  font-size: 1em;
  padding: 1em;
  padding-top: 0.5em;
  padding-bottom: 1.5em;
  margin-top: -0.5em;
  font-size: 0.95em;
  font-style: italic;
  line-height: 1.8em;
  letter-spacing: 0.0125em;
  color: ${p => (p.current ? '#555' : '#aaa')};
  & i {
    font-style: normal;
  }
`;

const Sentence = ({ isUnderCursor, getPreference, root: sentence }) => {
  const { elements } = sentence;

  return (
    <Linchpin active={isUnderCursor}>
      {scrollRefs => (
        <Wrapper>
          <StyledSentence current={isUnderCursor} {...scrollRefs}>
            <JumpContainer>
              <JumpTo element={sentence} isCurrent={isUnderCursor} />
            </JumpContainer>
            <ElementTree elements={elements} />
          </StyledSentence>
          {getPreference(preferenceKeys.SHOW_INLINE_TRANSLATION) && (
            <Translation
              current={isUnderCursor}
              {...parseUnderscores(sentence.translation)}
            />
          )}
        </Wrapper>
      )}
    </Linchpin>
  );
};

export default enhance(Sentence);
