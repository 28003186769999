import React, { useEffect } from 'react';
import { parseQuery } from '../lib/parse-query';

import { EpisodeProvider } from '../contexts/episode-context';

import LoadingEpisode from '../components/loading-episode';
import Layout from './layout';

const Adhoc = () => {
  const [data, setData] = React.useState(null);

  useEffect(() => {
    const { url = null } = parseQuery();
    if (url) {
      fetch(url)
        .then(response => response.json())
        .then(data => setData(data))
        .catch(error => console.error(error));
    }
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div>
      <EpisodeProvider fullData={data}>
        <LoadingEpisode component={Layout} />
      </EpisodeProvider>
    </div>
  );
};

export default Adhoc;
