export const createMinibus = () => {
  let __subscriptions = {};

  const emit = eventName => {
    const listeners = __subscriptions[eventName] || [];

    // flag to tell if execution should continue;
    let propagate = true;
    for (let i = listeners.length - 1; i >= 0; i--) {
      if (listeners[i]) {
        propagate = listeners[i]();
      }
      if (propagate === false) {
        break;
      }
    }
  };

  // creates a subscription and returns the unsubscribe function;
  const subscribe = (name, callback) => {
    if (typeof __subscriptions[name] === 'undefined') {
      __subscriptions[name] = [];
    }
    __subscriptions[name].push(callback);
    return {
      unsubscribe: () => {
        const index = __subscriptions[name].indexOf(callback);
        __subscriptions[name].splice(index, 1);
      },
    };
  };

  const subscribeToMany = (dictionary = {}) => {
    const keys = Object.keys(dictionary);
    const subscriptions = keys.map(subName => {
      const callback = dictionary[subName];
      return subscribe(subName, callback);
    });

    return {
      unsubscribeFromAll: () => {
        subscriptions.forEach(sub => sub.unsubscribe());
      },
    };
  };

  return { emit, subscribe, subscribeToMany };
};

const singleton = createMinibus();
window.minibus = singleton;
export default singleton;
