import React from 'react';
import ElementTree from './element-tree';
import PassageHint from './passage-hint';

import { withCursor } from '../../contexts/cursor-context';
import styled from 'styled-components';

const StyledPassage = styled.div`
  margin-bottom: 2em;
  /* padding-left: 1em; */
  border-left: 4px solid ${p => (p.current ? 'RebeccaPurple' : 'transparent')};
`;

const Passage = ({ isUnderCursor, cursor, root: passage }) => {
  const { elements } = passage;
  return (
    <StyledPassage current={isUnderCursor}>
      {passage.hint && (
        <PassageHint passage={passage} current={isUnderCursor} />
      )}
      <ElementTree elements={elements} />
    </StyledPassage>
  );
};

export default withCursor()(Passage);
