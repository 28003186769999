import React from 'react';
import PropTypes from 'prop-types';
import Element from './element';

const getKey = element => {
  if (element.address) {
    return element.address;
  }
  // maybe it's a word_group
  // TODO: explain or illustrate this logic
  return `${element.group_type || element.groupType}.${
    element.elements.length
  }.${element.elements[0].address}`;
};

/**
 * Renders a complete tree of elements.
 * We use this to prerender the complete chapter in
 * the transcript view before playing the chapter.
 */
const ElementTree = ({ elements = [], ...props }) =>
  elements.map(element => {
    if (!element) {
      return null;
    }

    return <Element root={element} key={getKey(element)} {...props} />;
  });

ElementTree.propTypes = {
  elements: PropTypes.array,
};

export default ElementTree;
