/* eslint jsx-a11y/accessible-emoji:0 */
import React from 'react';
import Keyboardist from 'react-keyboardist';
import { compose } from 'recompose';
import styled from 'styled-components';

import UnfocusButton from '../components/unfocus-button';
import { withTransportControls } from '../contexts/cursor-context';
import {
  preferenceKeys,
  REVEAL_ALL,
  REVEAL_NONE,
  REVEAL_VISITED,
  withPreferences,
} from '../contexts/preferences-context';
import {
  emitWordGroupResetTooltips,
  emitWordGroupShowVisitedTooltips,
} from '../lib/emitters';
import TransportHelp from './transport-help';

const enhance = compose(withTransportControls, withPreferences);

const TransportContainer = styled.div`
  background: #cae6e7;
  background: #73bbc8;
  background: #333b38;
  background: #0c1214;
  border-top: 1px solid #f0f0f0;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TransportDivider = styled.div`
  width: 0;
  height: 40px;
  border-right: 1px solid #192528;
  margin: 0 12px;
`;

const TransportButton = styled(UnfocusButton)`
  font-family: ${p => p.theme.sansSerif};
  font-size: 16px;
  border: none;
  background: #444;
  color: gray;
  color: #eee;
  padding: 8px;
  border-radius: 6px;
  margin: 0 10px;
  font-size: 13px;
  cursor: pointer;
  min-width: 10em;
  &:hover {
    background: ${p => p.theme.jumpColor};
    color: white;
  }
`;

const ToolButton = styled(UnfocusButton)`
  font-family: ${p => p.theme.sansSerif};
  font-size: 16px;
  border: none;
  background: transparent;
  color: gray;
  color: #eee;
  padding: 6px;
  border-radius: 6px;
  margin: 0 6px;
  font-size: 12px;
  cursor: pointer;
  /* min-width: 11em; */
  &:hover {
    background: ${p => p.theme.jumpColor};
    color: white;
  }
`;

const ButtonKey = styled.span`
  text-decoration: underline;
`;

class Transport extends React.Component {
  togglePlay = () => {
    const { isChapterPlaying, playChapter, pauseChapter } = this.props;
    if (isChapterPlaying) {
      pauseChapter();
    } else {
      playChapter();
    }
  };

  setRevealWordLevel = evt => {
    this.props.setPreference(
      preferenceKeys.REVEAL_WORDS_LEVEL,
      parseInt(evt.target.value, 10)
    );
  };

  revealLevelSetter = level => () => {
    this.props.setPreference(preferenceKeys.REVEAL_WORDS_LEVEL, level);
  };

  toggleAutoshowTooltips = () => {
    this.props.togglePreference(preferenceKeys.AUTO_SHOW_TOOLTIPS);
  };

  togglePauseOnHints = () => {
    this.props.togglePreference(preferenceKeys.PAUSE_ON_HINTS);
  };

  showAllVisitedTooltips() {
    emitWordGroupShowVisitedTooltips();
  }

  resetAllToolTips() {
    emitWordGroupResetTooltips();
  }

  toggleTranslation = () =>
    this.props.togglePreference(preferenceKeys.SHOW_INLINE_TRANSLATION, () => {
      this.resetAllToolTips();
    });

  render() {
    const {
      getPreference,
      isChapterPlaying,
      restart,
      rewind,
      unwind,
      jumpToLatest,
      fastForward,
      fastRewind,
    } = this.props;

    const showingTooltips = getPreference(preferenceKeys.AUTO_SHOW_TOOLTIPS);
    const showingTranslation = getPreference(
      preferenceKeys.SHOW_INLINE_TRANSLATION
    );
    const pausingOnHints = getPreference(preferenceKeys.PAUSE_ON_HINTS);

    return (
      <TransportContainer>
        <Keyboardist
          bindings={{
            Home: restart,
            Space: this.togglePlay,
            Enter: jumpToLatest,
            Left: rewind,
            Right: unwind,
            Up: fastRewind,
            Down: fastForward,
            KeyT: this.toggleAutoshowTooltips,
            KeyR: this.toggleTranslation,
            Comma: this.revealLevelSetter(REVEAL_ALL),
            Period: this.revealLevelSetter(REVEAL_VISITED),
            Slash: this.revealLevelSetter(REVEAL_NONE),
          }}
        />
        <TransportHelp />
        <ToolButton onClick={this.togglePauseOnHints}>
          <input type="checkbox" checked={pausingOnHints} />
          Pause on hints
        </ToolButton>
        <ToolButton onClick={this.toggleAutoshowTooltips}>
          <input type="checkbox" checked={showingTooltips} />
          Autoshow Tooltips
        </ToolButton>
        <ToolButton onClick={this.resetAllToolTips}>Clear</ToolButton>
        <ToolButton onClick={this.showAllVisitedTooltips}>
          Show Previous
        </ToolButton>
        <TransportDivider />
        <ToolButton onClick={this.toggleTranslation} title="[r]">
          <input type="checkbox" checked={showingTranslation} />T
          <ButtonKey>r</ButtonKey>
          anslations
        </ToolButton>
        <TransportDivider />
        <TransportButton onClick={rewind} title="[left arrow]">
          ⏪ Rewind
        </TransportButton>
        <TransportButton onClick={this.togglePlay} title="[spacebar]">
          {isChapterPlaying ? '⏸ Pause' : '▶️ Play'}
        </TransportButton>
        <TransportDivider />

        <TransportButton onClick={restart} title="[home]">
          🔄 Restart
        </TransportButton>
      </TransportContainer>
    );
  }
}

export default enhance(Transport);
