import React from 'react';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  node = null;

  constructor(props) {
    super(props);
    this.node = document.createElement('DIV');
    this.node.setAttribute('class', '__portal__');
  }

  componentDidMount() {
    document.body.appendChild(this.node);
  }

  componentWillUnmount() {
    document.body.removeChild(this.node);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.node);
  }
}

export default Portal;
