import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import { withRouter } from 'react-router';
import { withCursor } from '../../contexts/cursor-context';
import {
  didCursorStateChange,
  didChapterChange,
} from '../../lib/make-pure-elements';
import {
  preferenceKeys,
  withPreferences,
} from '../../contexts/preferences-context';
import {
  WORDGROUP_RESET_TOOLTIPS,
  WORDGROUP_SHOW_VISITED_TOOLTIPS,
} from '../../lib/emitters';
import minibus from '../../lib/minibus';
import Spacing from '../elements/spacing';
import Popover from '../popover';
import ElementTree from './element-tree';

const enhance = compose(
  withPreferences,
  withRouter,
  withCursor({ makePure: false }) // opting-out of the default shouldUpdate calculations.
);

const Group = styled.div`
  display: inline-block;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid transparent;
  background: ${props => (props.selected ? '#ffe9f3' : 'transparent')};
  cursor: default;
`;

// TODO: factor color values into functionally named constants - we'll be reusing them
const componentTypes = {
  normal: Group,
  vocab: styled(Group)`
    border-bottom: 2px solid ${p => p.theme.vocabColor};
  `,
  tricky: styled(Group)`
    font-style: cursive;
    background-color: #fff8a6;
    ${({ withToolTip, theme }) =>
      withToolTip && `border-bottom: 2px solid ${theme.trickyColor};`}
  `,
  sic: styled(Group)`
    border-bottom: 2px solid ${p => p.theme.sicColor};
  `,
};

const SicAnnotation = styled.span`
  font-family: ${p => p.theme.sansSerif};
  font-style: italic;
  font-size: 0.9em;
`;

class WordGroup extends React.Component {
  _ref = React.createRef();

  minibus = null;

  state = {
    selected: false,
  };

  hasTooltip(props = this.props) {
    const type = props.root.group_type || props.root.groupType;
    const { note } = props.root;

    return type === 'vocab' || type === 'sic' || (type === 'tricky' && note);
  }

  subscribe() {
    if (this.hasTooltip()) {
      this.minibus = minibus.subscribeToMany({
        [WORDGROUP_SHOW_VISITED_TOOLTIPS]: this.handleSelectBusEvent,
        [WORDGROUP_RESET_TOOLTIPS]: this.handleUnselectBusEvent,
      });
    }
  }

  unsubscribe() {
    if (this.minibus !== null) {
      this.minibus.unsubscribeFromAll();
    }
    this.minibus = null;
  }

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  // What is this function?
  shouldComponentUpdate(nextProps, nextState) {
    const should =
      didChapterChange(this.props, nextProps) ||
      didCursorStateChange(this.props, nextProps);

    // chapter/cursor state takes precedence.
    if (should === true) {
      return true;
    }

    const willHaveTooltip = this.hasTooltip(nextProps);
    const hasTooltip = this.hasTooltip();
    if (hasTooltip !== willHaveTooltip) {
      // recycled component
      console.log(
        `Will recycle: hasTooltip = ${hasTooltip}, willHaveTooltip = ${willHaveTooltip}`
      );
      return true;
    }

    if (this.state.selected !== nextState.selected) {
      return true;
    }

    if (hasTooltip) {
      if (this.props.isUnderCursor !== nextProps.isUnderCursor) {
        return true;
      }

      if (this.props.isBeforeCursor && !nextProps.isBeforeCursor) {
        return true;
      }
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const hadTooltip = this.hasTooltip(prevProps);
    const hasTooltip = this.hasTooltip();

    // if this component is being recycled check it's tooltip behavior needs resetting
    if (hasTooltip !== hadTooltip) {
      console.log(
        `Is recycled: hadTooltip = ${hadTooltip}, hasTooltip = ${hasTooltip}`
      );
      this.unsubscribe();
      this.subscribe();
      this.unselectGroup();
      return;
    }

    if (hasTooltip) {
      if (
        this.props.isUnderCursor &&
        this.props.getPreference(preferenceKeys.AUTO_SHOW_TOOLTIPS)
      ) {
        this.selectGroup();
        return;
      }
      // if we've rewound to before this word group then we hide the tooltip
      if (prevProps.isBeforeCursor && !this.props.isBeforeCursor) {
        this.unselectGroup();
      }
    }
  }

  handleSelectBusEvent = () => {
    if (!this.hasTooltip()) {
      return;
    }
    if (this.props.isBeforeCursor) {
      this.selectGroup();
    }
  };

  handleUnselectBusEvent = () => {
    if (this.isSelected()) {
      return this.unselectGroup();
    }
  };

  handleClick = () => {
    if (!this.hasTooltip()) {
      return;
    }

    if (!this.isSelected()) {
      this.selectGroup();
    } else {
      this.unselectGroup();
    }
  };

  selectGroup = () => {
    if (!this.state.selected) {
      this.setState({ selected: true });
    }
  };

  unselectGroup = () => {
    if (this.state.selected) {
      this.setState({ selected: false });
    }
  };

  isSelected = () => {
    return this.state.selected === true;
  };

  shouldShowTooltip = () => {
    return this.isSelected();
  };

  render() {
    const { root: group } = this.props;
    const { elements, head = '', note = '' } = group;
    const type = group.group_type || group.groupType;
    const GroupContainer = componentTypes[type] || Group;
    const showTip = this.shouldShowTooltip();

    if (!this.hasTooltip()) {
      return (
        <GroupContainer>
          <ElementTree
            elements={elements}
            groupType={type}
            withToolTip={false}
          />
        </GroupContainer>
      );
    }

    return (
      <Popover
        show={showTip}
        as={GroupContainer}
        note={note}
        head={head}
        onClick={this.handleClick}
        selected={false}
        type={type}
        withToolTip={true}
      >
        <ElementTree
          elements={elements}
          selectGroup={this.selectGroup}
          unselectGroup={this.unselectGroup}
          groupType={type}
        />
        {type === 'sic' && <SicAnnotation>(sic)</SicAnnotation>}
        <Spacing />
      </Popover>
    );
  }
}

export default enhance(WordGroup);
