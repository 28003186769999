import React from 'react';

import { EpisodeProvider } from '../contexts/episode-context';

import LoadingEpisode from '../components/loading-episode';
import Layout from './layout';
export default ({ match }) => {
  // this value comes from the URL, provided by react-router
  const {
    params: { episodeSlug = null },
  } = match;

  return (
    <div>
      <EpisodeProvider slug={episodeSlug}>
        <LoadingEpisode component={Layout} />
      </EpisodeProvider>
    </div>
  );
};
