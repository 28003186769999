import React from 'react';
import { withEpisode } from '../contexts/episode-context';

const Loading = ({ isEpisodeLoading = false, component: Component }) => {
  if (isEpisodeLoading) {
    return <h1>Loading Episode</h1>;
  }

  return <Component />;
};

export default withEpisode(Loading);
