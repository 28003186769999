import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import Spacing from './spacing';
import { withCursor } from '../../contexts/cursor-context';
import {
  REVEAL_ALL,
  REVEAL_NONE,
  REVEAL_VISITED,
  preferenceKeys,
  withPreferences,
} from '../../contexts/preferences-context';

const enhance = compose(
  withCursor(),
  withPreferences
);

const WordWrapper = styled.div`
  display: inline;
  font-family: ${p => p.theme.sansSerif};
  line-height: 1.5em;
`;

const WordText = styled.span`
  display: inline-block;
  border-bottom: 2px solid
    ${p => (p.current ? p.theme.jumpColor : 'transparent')};
  color: ${p => (p.isVisited ? 'inherit' : '#aaa')};
  font-style: ${p => (p.italicized ? 'italic' : 'normal')};
`;

const RedactedOuter = styled.span`
  display: inline-block;
  color: transparent;
  position: relative;
  background: transparent;
`;

const RedactedInner = styled.span`
  position: absolute;
  background: ${({ current }) => (current ? 'black' : 'gray')};
  height: 10px;
  width: 100%;
  margin-top: -5px;
  top: 50%;
  left: 0;
  border-radius: 5px;
  border-bottom: 1px solid transparent;
`;

const Redacted = ({ current, children }) => (
  <RedactedOuter current={current}>
    <RedactedInner current={current} />
    {children}
  </RedactedOuter>
);

const Word = ({ getPreference, isUnderCursor, isVisited, root: word }) => {
  const revealWords = getPreference(preferenceKeys.REVEAL_WORDS_LEVEL);
  let showWord;

  switch (revealWords) {
    case REVEAL_ALL:
      showWord = true;
      break;

    case REVEAL_VISITED:
      showWord = isVisited || isUnderCursor;
      break;

    case REVEAL_NONE:
      showWord = false;
      break;

    default:
      break;
  }

  return (
    <WordWrapper current={isUnderCursor}>
      {showWord && (
        <WordText
          current={isUnderCursor}
          isVisited={isVisited}
          italicized={word.italicized}
        >
          {word.text}
        </WordText>
      )}
      {!showWord && <Redacted current={isUnderCursor}>{word.text}</Redacted>}
      <Spacing />
    </WordWrapper>
  );
};

export default enhance(Word);
