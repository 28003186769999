import React from 'react';
import styled from 'styled-components';
import { withCursor } from '../../contexts/cursor-context';

const Gap = styled.div`
  width: 10em;
  height: 1em;
  visibility: ${p => (p.current ? 'default' : 'hidden')};
`;

const MusicOrSilence = ({ isUnderCursor }) => {
  return (
    <Gap current={isUnderCursor}>
      <span role="img" aria-label="music-or-silence">
        🎶
      </span>
    </Gap>
  );
};

export default withCursor()(MusicOrSilence);
