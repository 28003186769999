import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: ${p => p.theme.sansSerif};
  font-size: 11px;
  background: #e9e9e9;
  position: absolute;
  bottom: 100%;
  right: 0;
  padding: 10px;
  display: flex;
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8em;
`;

const Key = styled.div`
  font-weight: bold;
  padding: 3px 5px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  color: #555;
  margin-right: 0.5em;
  border-radius: 3px;
`;

const shortcuts = [
  { key: 'space', description: 'Play/Pause' },
  { key: 'T', description: 'Tooltips toggle' },
  { key: 'R', description: 'Translation toggle' },
  { key: 'left', description: 'rewind' },
  { key: 'right', description: 'un-rewind' },
  { key: 'enter', description: 'jump to furthest listened' },
  { key: 'up/down', description: 'prev/next passage' },
];

export default () => (
  <Wrapper>
    {shortcuts.map(def => (
      <Item key={def.key}>
        <Key>{def.key}</Key>
        <span>{def.description}</span>
      </Item>
    ))}
  </Wrapper>
);
